const CONFIGURATION = {
  API_URL: 'https://apiprep.dongu.com/en/',
  TIMEOUT: 15000,
  TOKEN_TYPE: 'JWT',
  WITH_CREDENTIALS: false,
};

function xmlFetch(config) {
  const URL = CONFIGURATION.API_URL + config.path;

  return new Promise((resolve, reject) => {
    const xmlHttpRequest = new XMLHttpRequest();
    xmlHttpRequest.timeout = CONFIGURATION.TIMEOUT;
    xmlHttpRequest.ontimeout = () => reject(new Error('Zaman aşımı!'));
    xmlHttpRequest.onload = () => {
      const { status, response } = xmlHttpRequest;

      let parsedResponse;
      try {
        parsedResponse = JSON.parse(response);
      } catch (error) {
        parsedResponse = response;
      }

      switch (parseInt(status / 100, 10)) {
        case 2: // Success
          resolve(parsedResponse);
          break;
        case 4: // Client Error
          reject(parsedResponse);
          break;
        case 5: // Server Error
          reject(new Error(`Durum: ${status}, Sistemde bir hata oluştu`));
          break;
        default:
          reject(new Error(`Bilinmeyen hata! Durum:${status}`));
      }
    };

    xmlHttpRequest.open(config.method, URL);

    xmlHttpRequest.withCredentials = CONFIGURATION.WITH_CREDENTIALS;

    if (config.sendToken)
      xmlHttpRequest.setRequestHeader(
        'Authorization',
        `${CONFIGURATION.TOKEN_TYPE} ${window.localStorage.getItem('token')}`
      );

    if (config.body) {
      const { body } = config;
      if (body instanceof FormData) {
        xmlHttpRequest.send(body);
      } else if (body instanceof Object) {
        xmlHttpRequest.setRequestHeader('Content-type', 'application/json');
        xmlHttpRequest.send(JSON.stringify(body));
      }
    } else xmlHttpRequest.send();
  });
}

export default xmlFetch;
